.header{
    /* background-color: chocolate; */
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
   
    
}
.header img{
    width: 13rem;
   
}

.header-menu ul{
    list-style: none;
    display: flex;
    gap: 2rem;
    font-weight: bold;
   
}
.header-menu li:hover{
    cursor: pointer;
    color: rgb(107, 89, 55);

}


@media  screen and (max-width:786px) {
    .header{
        padding: 1rem 0;
    }
    .header>:nth-child(1){
        width: 10rem;
        
    }
     .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;

     }
     .header-menu>ul{
        display: flex;
        flex-direction: column;
        background-color: rgb(86, 76, 76);
        padding: 2rem;
       color: white;
     }
     

}