.Header{
    height: 100vh;
    width: 100%;
    background-color: burlywood;
    display: flex;
    justify-content: space-between;
    
    /* align-items: center; */
    
}
.left-H{
    flex: 3;
    padding: 0px 0px 0px 20px;
    
}
.right-H{
    flex: 1;
    /* display: flex; */
    /* gap: 2rem; */
    /* flex-direction: column; */
    background-color: orange;
    position: relative;
}
.best-ad{
    margin-top: 5rem;
    margin-left: 3rem;
    background-color: rgb(255, 255, 255);
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.best-ad span{
    z-index: 2;
}
.best-ad div{
    position: absolute;
    background-color: orange;
    width: 5rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
    margin-left: 25px;
    margin-top: 20px;
}
.hero-text>div:nth-of-type(3)
{
    font-size: 1rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 70%;
    

}
.figures{
    display: flex;
    gap: 2rem;
    margin-left: 20px;
    margin-top: 65px;
}
.figures div{
    display: flex;
    flex-direction: column;
}
.figures div span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures div span:nth-of-type(2){
    color: floralwhite;
    text-transform: uppercase;
}
.hero-buttons{
 display: flex;
 gap: 1rem;
 font-weight: normal;
 margin-top: 25px;
 margin-left: 20px;
}
.hero-buttons :nth-child(1)
{
    color: white;
    background-color: orange;
    width: 8rem;
}
.hero-buttons :nth-child(2)
{
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
}
.right-H .btn{
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: rgb(213, 199, 199);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate img{
    width: 2rem;
}
.heart-rate>:nth-child(2){
    color: rgb(45, 21, 21);
    
}
.heart-rate>:nth-child(3){
    color: black;
    font-size: 1.5rem;

}
.hero-image{
    position: absolute;
    top: 9rem;
    right: 8rem;
    width: 23rem;
    z-index: 1;
    transition: all 0.6s;
}
.hero-image:hover{
    filter: drop-shadow(3px 3px 10px rgb(0, 0, 0));
}
.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
   width: 15rem;
}
.calories{
    display: flex;
    
    gap: 2rem;
    background-color: gray;
    border-radius: 5px;
    padding: 1rem;
    position: absolute;
    width: fit-content;
    top: 32rem;
    right: 30rem;
}
.calories img{
    width: 3rem;
}
.calories div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 8rem;
}
.calories div>:nth-child(1){
    color: black;
    font-size: 1rem;
    
}
.calories div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}
.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
    z-index: 0;
}

@media  screen and (max-width:786px) {
    .Header{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }
    .hero-blur{
        width: 14rem;
    }
    .best-ad{
        margin-top: 10px;
        font-size: small;
        align-self: center;
        /* margin-left: 0px; */
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }
    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
        
    }
    .hero-buttons{
        justify-content: center;
        margin-left: 0px;
    }
    .figures{
        justify-content: center;
        margin-left: 0px;
    }
    .figures>div>span:nth-of-type(1){
        font-size: large;
        
    }
    .figures>div>span:nth-of-type(2){
        font-size: small;
    }
    .right-H{
        position: relative;
        background: none;
    }
    .heart-rate{
        height: 7rem;
        width: 6rem;
        left: 1rem;
        top: 2rem;
        z-index: 1;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img{
        width: 2rem;
    }
    .calories>div>:nth-child(2){
        color: white;
        font-size: 1rem;
    }
    .hero-image{
        position: relative;
        width: 13rem;
        left: 8rem;
        top: 5rem;
        align-self: center;
    }
    .hero-image-back{
        width: 10rem;
        left: 4rem;
        top:5rem;
    }


}