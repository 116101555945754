.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: gray;
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    transition: .2s;
}
/* .plan:hover{
    transform: scale(1.05);
    box-shadow: 3px 3px 10px rgb(246, 240, 240),-3px -3px 10px rgb(238, 233, 233);
} */
.plan:nth-child(2){
    background: rgb(113, 93, 55);
    transform: scale(1.1);
}
/* .plan:nth-child(2):hover{
    transform: scale(1.15);
} */
.plan svg{
    fill: orange;
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(4){
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature img{
    width: 1rem;
}

.plans>:nth-child(2)>button{
    color: orange;
    font-weight: bold;
}
.plans-blur1{
    width: 23rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
    z-index: -1;
}
.plans-blur2{
    width: 23rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
    z-index: -1;
}

@media  screen and (max-width:786px) {
    .plans{
        flex-direction: column;
        gap: 1rem;
    }
    .plan:nth-child(2){
        transform: scale(.90);
    }
    
    .plan{
        transform: scale(.90);
    }
   

}